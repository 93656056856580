// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("CMUSansSerif.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("CMUSansSerif-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("CMUSansSerif-Oblique.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("CMUSansSerif-BoldOblique.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: normal;\n  font-weight: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: normal;\n  font-weight: bold;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: italic;\n  font-weight: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: italic;\n  font-weight: bold;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n", "",{"version":3,"sources":["webpack://./assets/fonts/cpm-vsalvino/sans.css"],"names":[],"mappings":"AAAA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,4CAA8B;AAChC;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,4CAAmC;AACrC;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,4CAAsC;AACxC;AACA;EACE,mCAAmC;EACnC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,4CAA0C;AAC5C","sourcesContent":["@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: normal;\n  font-weight: normal;\n  font-display: swap;\n  src: url(\"CMUSansSerif.woff2\");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: normal;\n  font-weight: bold;\n  font-display: swap;\n  src: url(\"CMUSansSerif-Bold.woff2\");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: italic;\n  font-weight: normal;\n  font-display: swap;\n  src: url(\"CMUSansSerif-Oblique.woff2\");\n}\n@font-face {\n  font-family: \"Computer Modern Sans\";\n  font-style: italic;\n  font-weight: bold;\n  font-display: swap;\n  src: url(\"CMUSansSerif-BoldOblique.woff2\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
