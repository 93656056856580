// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("cmunui.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("cmunui.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("cmunui.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___, { hash: "#cmunui" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'Computer Modern Upright Italic';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('svg');\n\tfont-weight: normal;\n\tfont-style: italic;\n}\n\n", "",{"version":3,"sources":["webpack://./assets/fonts/cpm-dreampulse/Upright Italic/cmun-upright-italic.css"],"names":[],"mappings":"AAAA;CACC,6CAA6C;CAC7C,4CAAsB;CACtB;;wDAEwC;CACxC,mBAAmB;CACnB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Computer Modern Upright Italic';\n\tsrc: url('cmunui.eot');\n\tsrc: url('cmunui.eot?#iefix') format('embedded-opentype'),\n\t\t url('cmunui.woff') format('woff'),\n\t\t url('cmunui.svg#cmunui') format('svg');\n\tfont-weight: normal;\n\tfont-style: italic;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
