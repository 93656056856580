// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("cmunsl.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("cmunsl.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("cmunsl.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("cmunsl.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("cmunbl.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("cmunbl.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("cmunbl.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("cmunbl.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#cmunsl" });
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___, { hash: "#cmunbl" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'Computer Modern Serif Slanted';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n\n@font-face {\n\tfont-family: 'Computer Modern Serif Slanted';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ");\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format('embedded-opentype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format('woff'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format('truetype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format('svg');\n\tfont-weight: bold;\n\tfont-style: normal;\n}\n", "",{"version":3,"sources":["webpack://./assets/fonts/cpm-dreampulse/Serif Slanted/cmun-serif-slanted.css"],"names":[],"mappings":"AAAA;CACC,4CAA4C;CAC5C,4CAAsB;CACtB;;;wDAGwC;CACxC,mBAAmB;CACnB,kBAAkB;AACnB;;;AAGA;CACC,4CAA4C;CAC5C,4CAAsB;CACtB;;;wDAGwC;CACxC,iBAAiB;CACjB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Computer Modern Serif Slanted';\n\tsrc: url('cmunsl.eot');\n\tsrc: url('cmunsl.eot?#iefix') format('embedded-opentype'),\n\t\t url('cmunsl.woff') format('woff'),\n\t\t url('cmunsl.ttf') format('truetype'),\n\t\t url('cmunsl.svg#cmunsl') format('svg');\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n\n@font-face {\n\tfont-family: 'Computer Modern Serif Slanted';\n\tsrc: url('cmunbl.eot');\n\tsrc: url('cmunbl.eot?#iefix') format('embedded-opentype'),\n\t\t url('cmunbl.woff') format('woff'),\n\t\t url('cmunbl.ttf') format('truetype'),\n\t\t url('cmunbl.svg#cmunbl') format('svg');\n\tfont-weight: bold;\n\tfont-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
