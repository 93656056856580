// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("cmunssdc.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("cmunssdc.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("cmunssdc.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("cmunssdc.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___, { hash: "#cmunssdc" });
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n\tfont-family: 'Computer Modern Sans Demi-Condensed';\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('embedded-opentype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('truetype'),\n\t\t url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('svg');\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n", "",{"version":3,"sources":["webpack://./assets/fonts/cpm-dreampulse/Sans Demi-Condensed/cmun-sans-demicondensed.css"],"names":[],"mappings":"AAAA;CACC,kDAAkD;CAClD,4CAAwB;CACxB;;;wDAG4C;CAC5C,mBAAmB;CACnB,kBAAkB;AACnB","sourcesContent":["@font-face {\n\tfont-family: 'Computer Modern Sans Demi-Condensed';\n\tsrc: url('cmunssdc.eot');\n\tsrc: url('cmunssdc.eot?#iefix') format('embedded-opentype'),\n\t\t url('cmunssdc.woff') format('woff'),\n\t\t url('cmunssdc.ttf') format('truetype'),\n\t\t url('cmunssdc.svg#cmunssdc') format('svg');\n\tfont-weight: normal;\n\tfont-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
