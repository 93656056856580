import { ChangeEvent, useEffect, useRef, useState } from "react";
import "./../assets/scss/App.scss";
import * as wasm from "rustbake-wasm";

// const reactLogo = require("./../assets/img/react_logo.svg");

const lpad = (s: string | number) => ("0" + s).slice(-2);

function isCheckboxEvent(
  ev: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
): ev is ChangeEvent<HTMLInputElement> {
  return ev.target?.type === "checkbox";
}

function isFileEvent(
  ev: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
): ev is ChangeEvent<HTMLInputElement> {
  return ev.target?.type === "file";
}

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
function useEffectSkipMount(fn: () => void, args: any[]) {
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      fn();
    }
  }, args);

  useEffect(() => {
    isMounted.current = true;
  }, []);
}

const App = () => {
  const date = useRef(new Date());

  const [recipes, setRecipes] = useState<{ name: string }[]>([]);
  const [initFetchDone, setInitFetchDone] = useState(false);
  const [inputs, setInputs] = useState({
    recipeSelect: "",
    recipeFilePath: "",
    recipeFile: null,
    endDate: ((d) =>
      `${d.getFullYear()}-${lpad(d.getMonth() + 1)}-${lpad(d.getDate())}`)(
      date.current,
    ),
    endTime: `${lpad(date.current.getHours())}:00`,
  });
  const [recipe, setRecipe] = useState();
  const [schedule, setSchedule] = useState<
    {
      time: string;
      caption: string;
      duration: string;
    }[]
  >();

  /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
  function setInput(name: string, value: any) {
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function fetchRecipe() {
    fetch(`/recipes_json/${inputs.recipeSelect}`)
      .then((r) => r.json())
      .then((j) => {
        setRecipe(j);
        setInput("recipeFilePath", "");
        setInput("recipeFile", null);
      });
  }

  function readRecipeFromFile() {
    const reader = new FileReader();
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      setRecipe(JSON.parse(ev?.target?.result?.toString() || "{}"));
      setInput("recipeSelect", "");
    };
    // TODO: fix unsafe type assertion.
    reader.readAsText(inputs.recipeFile as unknown as File);
  }

  function handleChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    setInput(
      e.target.name,
      ((ev) => {
        if (isCheckboxEvent(ev)) {
          return ev.target.checked;
        } else if (isFileEvent(ev)) {
          return ev.target.files?.[0] || null;
        } else {
          return ev.target.value;
        }
      })(e),
    );
    if (isFileEvent(e)) {
      setInput(`${e.target.name}Path`, e.target.value);
    }
  }

  function generateSchedule() {
    setSchedule(
      wasm
        .bake_schedule(
          JSON.stringify(recipe),
          `${inputs.endDate}T${inputs.endTime}`,
        )
        .trim()
        .split("\n")
        .map((step) =>
          (([time, caption, duration]) => ({
            time,
            caption,
            duration,
          }))(step.split("\t")),
        ),
    );
  }

  useEffect(() => {
    fetch("/recipes_json")
      .then((r) => r.json())
      .then((j: { name: string }[]) => setRecipes(j))
      .catch((e) => alert(e))
      .finally(() => setInitFetchDone(true));
  }, []);

  useEffectSkipMount(() => {
    if (!inputs.recipeSelect) {
      return;
    }
    fetchRecipe();
  }, [inputs.recipeSelect]);

  useEffectSkipMount(() => {
    if (!inputs.recipeFile) {
      return;
    }
    readRecipeFromFile();
  }, [inputs.recipeFile]);

  useEffectSkipMount(() => {
    if (!recipe || !inputs.endDate || !inputs.endTime) {
      return;
    }
    generateSchedule();
  }, [recipe, inputs.endDate, inputs.endTime]);

  return (
    <main className="app">
      <h1>Baking Scheduler</h1>
      <hr />
      <section>
        <form className="form">
          <ul className="flex-outer">
            <li>
              <label className="row">
                <span className="label-text column column-left">End date:</span>
                <input
                  className="column column-right"
                  type="date"
                  name="endDate"
                  value={inputs.endDate}
                  onChange={handleChange}
                />
              </label>
            </li>
            <li>
              <label className="row">
                <span className="label-text column column-left">End time:</span>
                <input
                  className="column column-right"
                  type="time"
                  name="endTime"
                  value={inputs.endTime}
                  onChange={handleChange}
                />
              </label>
            </li>
            <li>
              <label className="row">
                <span className="label-text column column-left">
                  Choose existing recipe:
                </span>
                <select
                  className="column column-right"
                  name="recipeSelect"
                  value={inputs.recipeSelect}
                  onChange={handleChange}
                  disabled={recipes.length === 0}
                >
                  <option value="" hidden>
                    --
                  </option>
                  {recipes.map((r) => (
                    <option key={r.name} value={r.name}>
                      {r.name}
                    </option>
                  ))}
                </select>
              </label>
              {initFetchDone && recipes.length === 0 && (
                <span className="error">No recipes found!</span>
              )}
              {!initFetchDone && recipes.length === 0 && (
                <span className="spinner">Loading recipes...</span>
              )}
            </li>
            <li>
              <label className="row">
                <span className="label-text column column-left">
                  Upload recipe file:
                </span>
                <input
                  className="column column-right"
                  type="file"
                  name="recipeFile"
                  value={inputs.recipeFilePath}
                  onChange={handleChange}
                />
              </label>
            </li>
          </ul>
        </form>
      </section>
      <section>
        <div className="links">
          <span className="link-inline">
            <a
              href={`/recipes_json/${inputs.recipeSelect}`}
              type="button"
              onClick={
                !inputs.recipeSelect ? (e) => e.preventDefault() : undefined
              }
              className={inputs.recipeSelect ? "link" : "link disabled"}
              download={inputs.recipeSelect}
            >
              Save recipe file
            </a>
          </span>
          <span className="link-inline">
            <a className="link" href="/recipes">
              Discover recipe files
            </a>
          </span>
        </div>
      </section>
      <hr />
      <section>
        {schedule && (
          <div className="schedule-table-container">
            <table className="schedule-table">
              <thead>
                <tr>
                  <th>Time</th>
                  <th>Step</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {schedule.map((x, i) => (
                  <tr key={i}>
                    <td className="td align-right">{x.time}</td>
                    <td>{x.caption}</td>
                    <td className="td align-right">{x.duration}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </section>
    </main>
  );
};

export default App;
