// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("ttf/Caveat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("ttf/Caveat-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"Caveat\";\n  font-style: normal;\n  font-weight: normal;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: \"Caveat\";\n  font-style: normal;\n  font-weight: bold;\n  font-display: swap;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", "",{"version":3,"sources":["webpack://./assets/fonts/caveat/font.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,4CAAkC;AACpC;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,4CAA+B;AACjC","sourcesContent":["@font-face {\n  font-family: \"Caveat\";\n  font-style: normal;\n  font-weight: normal;\n  font-display: swap;\n  src: url(\"ttf/Caveat-Regular.ttf\");\n}\n\n@font-face {\n  font-family: \"Caveat\";\n  font-style: normal;\n  font-weight: bold;\n  font-display: swap;\n  src: url(\"ttf/Caveat-Bold.ttf\");\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
